import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-notify',
  templateUrl: './notify.component.html',
  styleUrls: ['./notify.component.sass']
})
export class NotifyComponent implements OnInit {

  submitForm = new FormGroup({
    email: new FormControl('', [Validators.email, Validators.required])
  });

  constructor() { }

  ngOnInit(): void {
  }

  onSubmit(): void {
    console.log(this.submitForm.value);
  }

}
