import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-social',
  templateUrl: './social.component.html',
  styleUrls: ['./social.component.sass'],
})
export class SocialComponent implements OnInit {
  @Input() links: { link: string; icon: string }[] = [];

  constructor() {}

  ngOnInit(): void {}
}
