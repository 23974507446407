<form [formGroup]="submitForm" (ngSubmit)="onSubmit()">
  <div class="input-group input-group-newsletter">
    <input
      formControlName="email"
      type="email"
      class="form-control"
      placeholder="Enter email..."
      aria-label="Enter email..."
      aria-describedby="basic-addon"
    />
    <div class="input-group-append">
      <button class="btn btn-secondary" type="submit" [disabled]="!submitForm.valid">
        Notify Me!
      </button>
    </div>
  </div>
</form>
