<app-overlay></app-overlay>

<div class="masthead">
  <div class="masthead-bg"></div>
  <div class="container h-100">
    <div class="row h-100">
      <div class="col-12 my-auto">
        <div class="masthead-content text-white py-5 py-md-0">
          <h1 class="mb-3">Coming Soon!</h1>
          <p class="mb-5">
            We're working hard to finish the development of this site. Our
            target launch date is <strong>{{startDate}}</strong>! Sign up for
            updates using the form below!
          </p>
          <app-notify></app-notify>
        </div>
      </div>
    </div>
  </div>
</div>

<app-social [links]="links"></app-social>
