import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.sass']
})
export class AppComponent {
  title = 'parking';
  startDate = 'January, 2021';
  links = [
    // { link: '#', icon: 'twitter' },
    // { link: '#', icon: 'facebook-f' },
    // { link: '#', icon: 'instagram' },
  ];
}
